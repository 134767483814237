import React from 'react'
import abt1 from '../img/abt1.jpeg';
import abt2 from '../img/abt2.jpeg';
import abt3 from '../img/abt3.jpeg';
import abt4 from '../img/abt4.jpeg';



const AboutUs = () => {
  return (
   <>
   {/* <div className='container-fluid text-center mt-5 p-5'>
    <h1><u>About Us</u></h1>
    <h2>The Page is Being Designed <br />
    Thanks For Visiting !!</h2>
   </div> */}
     <div className="container-fluid py-5">
            <div className="container m-auto">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6">
                        <div className="row g-3">
                            <div className="col-6 text-start">
                                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src={abt1} alt="About 1" />
                            </div>
                            <div className="col-6 text-start">
                                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.3s" src={abt2} style={{ marginTop: '25%' }} alt="About 2" />
                            </div>
                            <div className="col-6 text-end">
                                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.5s" src={abt3} alt="About 3" />
                            </div>
                            <div className="col-6 text-end">
                                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src={abt4} alt="About 4" />
                            </div>
                        </div>
                    </div>
      <div className="col-lg-6">
        <h5 className="section-title text-start text-primary fw-normal"><q>FOUNDER AND PRINCIPAL</q>
        </h5>
        <h1 className="mb-4"> <i className="bi bi-person-fill text-primary me-2" /> GAURAV S. SHARMA</h1>
        <p className="mb-4">Innovative and technologically adept, Gaurav, a
          creative powerhouse, earned a post-graduate
          degree in Indian Theatre from Punjab University
          (2006) and a three-year Scenography and
          Direction diploma from NSD, New Delhi. In
          2010, he secured the Inlaks scholarship, the
          sole Indian candidate, for a two-year Stage
          Management and Technical Theatre diploma at
          LAMDA (2010-2012).</p>
        <p className="mb-4">Gaurav, a fusion of creativity and technology,
          encompasses diverse interests in Literature,
          Music, Art, and Architecture. While his
          composite personality involves lighting, writing,
          and scenography, his true passion lies in
          designing lights for various spaces.</p>
        <div className="row g-4 mb-4">
          <div className="col-sm-6">
            <div className="d-flex align-items-center border-start border-5 border-primary px-3">
              <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">15
              </h1>
              <div className="ps-4">
                <p className="mb-0">Years of</p>
                <h6 className="text-uppercase mb-0">Experience</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="d-flex align-items-center border-start border-5 border-primary px-3">
              <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">50
              </h1>
              <div className="ps-4">
                <p className="mb-0">Popular</p>
                <h6 className="text-uppercase mb-0">Master Clients</h6>
              </div>
            </div>
          </div>
        </div>
        <a className="btn btn-primary py-3 px-5 mt-2" href="aboutus.html">Know More</a>
      </div>
    </div>
  </div>
</div>
   </>
  )
}

export default AboutUs