import React from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css';
import abt1 from '../img/abt1.jpeg';
import abt2 from '../img/abt2.jpeg';
import abt3 from '../img/abt3.jpeg';
import abt4 from '../img/abt4.jpeg';
import ent1 from '../img/Entertainment/ent1.jpg';
import ent2 from '../img/Entertainment/ent2.jpg';
import ent3 from '../img/Entertainment/ent3.jpg';
import ent4 from '../img/Entertainment/ent4.jpg';
import ent5 from '../img/Entertainment/ent5.jpg';
import landscaping1 from '../img/architech/landscaping-1.jpeg';
import landscaping2 from '../img/architech/landscaping-2.jpeg';
import landscaping3 from '../img/architech/landscaping-3.jpeg';
import landscaping4 from '../img/architech/landscaping-4.jpeg';
import thematic1 from '../img/Thematic/thm4.jpeg';
import thematic2 from '../img/Thematic/thm2.jpeg';
import thematic3 from '../img/Thematic/thm5.jpeg';
import thematic4 from '../img/Thematic/thm3.jpeg';
import thematic5 from '../img/Thematic/thm6.jpeg';
import clt1 from '../img/CLIENT LOGO/clt1.png';
import clt2 from '../img/CLIENT LOGO/clt2.png';
import clt3 from '../img/CLIENT LOGO/clt3.jpg';
import clt4 from '../img/CLIENT LOGO/clt4.jfif';
import clt5 from '../img/CLIENT LOGO/clt5.png';
import clt6 from '../img/CLIENT LOGO/clt6.jpg';
import clt7 from '../img/CLIENT LOGO/clt7.png';
import clt9 from '../img/CLIENT LOGO/clt9.jpg';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import 'owl.carousel';

// import './App.css';

const Home = () => {
 
  return (
    <>
     <div className="container-fluid py-5 bg-dark hero-header mb-5">
      <div className="container my-5 py-5">
        <div className="row align-items-center g-5">
          <div className="col-lg-6 text-center text-lg-start">
            <h1 style={{ fontFamily: 'Rubik Mono One, monospace' }} className="display-3 text-white animated slideInLeft">
              Radiating Hope And Joy
              <br />
              In Every Life We Touch.
            </h1>
            <p className="text-white animated slideInLeft mb-4 pb-2">
              HARMONIC SYSTEMS was formed as a vanguard Design and Consultancy firm. Since decade the company has been
              associated with various projects in India and abroad, which covers various niches like Architectural,
              Entertainment and Thematics Illumination.
            </p>
            <a href="contactUs.html" className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInLeft">
              Contact Us
            </a>
          </div>
          <div className="col-lg-6 text-center text-lg-end overflow-hidden">
            {/* <img className="img-fluid img" style={{ borderRadius: '4%' }} src="img/tha.png" alt="" /> */}
          </div>
        </div>
      </div>
    </div>

    {/* Service Start */}
        <div class="container-fluid">
            <div class="container m-auto">
                <div class="row g-4 ">
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="service-item rounded pt-3">
                            <div class="p-4">
                                <i class="fa fa-3x bi bi-lightbulb-fill text-primary mb-4"></i>
                                <h5>LIGHT DESIGN + CONSUTANCY</h5>
                                <p>Expert lighting design and consultancy services that enhance and illuminate your spaces</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div class="service-item rounded pt-3">
                            <div class="p-4">
                                <i class="fa fa-3x bi bi-lamp-fill text-primary mb-4"></i>
                                <h5>TIME-CODE <br /> MANAGEMENT</h5>
                                <p>Efficiently track and manage project timelines with precision <br /> using time-code management.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="service-item rounded pt-3">
                            <div class="p-4">
                                <i class="fa fa-3x bi bi-lightbulb text-primary mb-4"></i>
                                <h5>VISUAL CONTENT + LASER DESIGN</h5>
                                <p>Enhance your creative projects with stunning visual content and intricate laser design elements.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                        <div class="service-item rounded pt-3">
                            <div class="p-4">
                                <i class="fa fa-3x bi bi-lamp-fill text-primary mb-4"></i>
                                <h5>CAD + PRE-VISUALIZATION</h5>
                                <p>CAD and pre-visualization techniques streamline design processes and enhance project accuracy.</p>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        {/* Service End */}

        {/* about */}

        <div className="container-fluid py-5">
            <div className="container m-auto">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6">
                        <div className="row g-3">
                            <div className="col-6 text-start">
                                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src={abt1} alt="About 1" />
                            </div>
                            <div className="col-6 text-start">
                                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.3s" src={abt2} style={{ marginTop: '25%' }} alt="About 2" />
                            </div>
                            <div className="col-6 text-end">
                                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.5s" src={abt3} alt="About 3" />
                            </div>
                            <div className="col-6 text-end">
                                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src={abt4} alt="About 4" />
                            </div>
                        </div>
                    </div>
      <div className="col-lg-6">
        <h5 className="section-title text-start text-primary fw-normal"><q>FOUNDER AND PRINCIPAL</q>
        </h5>
        <h1 className="mb-4"> <i className="bi bi-person-fill text-primary me-2" /> GAURAV S. SHARMA</h1>
        <p className="mb-4">Innovative and technologically adept, Gaurav, a
          creative powerhouse, earned a post-graduate
          degree in Indian Theatre from Punjab University
          (2006) and a three-year Scenography and
          Direction diploma from NSD, New Delhi. In
          2010, he secured the Inlaks scholarship, the
          sole Indian candidate, for a two-year Stage
          Management and Technical Theatre diploma at
          LAMDA (2010-2012).</p>
        <p className="mb-4">Gaurav, a fusion of creativity and technology,
          encompasses diverse interests in Literature,
          Music, Art, and Architecture. While his
          composite personality involves lighting, writing,
          and scenography, his true passion lies in
          designing lights for various spaces.</p>
        <div className="row g-4 mb-4">
          <div className="col-sm-6">
            <div className="d-flex align-items-center border-start border-5 border-primary px-3">
              <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">15
              </h1>
              <div className="ps-4">
                <p className="mb-0">Years of</p>
                <h6 className="text-uppercase mb-0">Experience</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="d-flex align-items-center border-start border-5 border-primary px-3">
              <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">50
              </h1>
              <div className="ps-4">
                <p className="mb-0">Popular</p>
                <h6 className="text-uppercase mb-0">Master Clients</h6>
              </div>
            </div>
          </div>
        </div>
        <a className="btn btn-primary py-3 px-5 mt-2" href="aboutus.html">Know More</a>
      </div>
    </div>
  </div>
</div>
 {/* About End */}

 
 {/* images */}
 <hr />

 <div className="one">
            <h1 className="mt-5 text-center"><q>ENTERTAINTMENT</q></h1>
            <div className="container m-auto">
                <div className="card">
                    <img src={ent1} alt="Entertainment 1" />
                    <div className="card__head">ENTERTAINTMENT-1</div>
                </div>
                <div className="card">
                    <img src={ent2} alt="Entertainment 2" />
                    <div className="card__head">ENTERTAINTMENT-2</div>
                </div>
                {/* <div className="card">
                    <img src={ent3} alt="Entertainment 3" />
                    <div className="card__head">ENTERTAINTMENT-3</div>
                </div> */}
                <div className="card">
                    <img src={ent4} alt="Entertainment 4" />
                    <div className="card__head">ENTERTAINTMENT-4</div>
                </div>
                <div className="card">
                    <img src={ent5} alt="Entertainment 5" />
                    <div className="card__head">ENTERTAINTMENT-5</div>
                </div>
            </div>
        </div>

        <div className="one">
            <h1 className="mt-5 text-center"><q>ARCHITECTURE</q></h1>
            <div className="container m-auto">
                <div className="card">
                    <img src={landscaping1} alt="Landscaping 1" />
                    <div className="card__head">Landscaping-1</div>
                </div>
                <div className="card">
                    <img src={landscaping2} alt="Landscaping 2" />
                    <div className="card__head">Landscaping-2</div>
                </div>
                <div className="card">
                    <img src={landscaping3} alt="Landscaping 3" />
                    <div className="card__head">Landscaping-3</div>
                </div>
                <div className="card">
                    <img src={landscaping4} alt="Landscaping 4" />
                    <div className="card__head">Landscaping-4</div>
                </div>
            </div>
        </div>  

        <div className="one">
            <h1 className="mt-5 text-center"><q>THEMATICS</q></h1>
            <div className="container m-auto">
                <div className="card">
                    <img src={thematic1} alt="Thematics 1" />
                    <div className="card__head">THEMATICS-1</div>
                </div>
                <div className="card">
                    <img src={thematic2} alt="Thematics 2" />
                    <div className="card__head">THEMATICS-2</div>
                </div>
                <div className="card">
                    <img src={thematic3} alt="Thematics 3" />
                    <div className="card__head">THEMATICS-3</div>
                </div>
                <div className="card">
                    <img src={thematic4} alt="Thematics 4" />
                    <div className="card__head">THEMATICS-4</div>
                </div>
                <div className="card">
                    <img src={thematic5} alt="Thematics 5" />
                    <div className="card__head">THEMATICS-5</div>
                </div>
            </div>
        </div>


        {/* clints */}
        <div className="image-grid-container mb-5 mt-5">
          <h1 className='text-center p-5 mt-5' >Our Major Clints</h1>
        <hr />
            <div className="row">
                <div className="col">
                    <img src={clt1} alt="Image 1" />
                </div>
                <div className="col">
                    <img src={clt2} alt="Image 2" />
                </div>
                <div className="col">
                    <img src={clt3} alt="Image 3" />
                </div>
                <div className="col">
                    <img src={clt4} alt="Image 4" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img src={clt5} alt="Image 5" />
                </div>
                <div className="col">
                    <img src={clt6} alt="Image 6" />
                </div>
                <div className="col">
                    <img src={clt7} alt="Image 7" />
                </div>
                <div className="col">
                    <img src={clt9} alt="Image 8" />
                </div>
            </div>
        </div>
    </>
  )
}

export default Home