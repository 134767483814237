import React from 'react'

const Expertise = () => {
  return (
    <>
    <div className="container-fluid text-center p-5 mt-5">
        <h1><u>Expertise Page</u></h1>
        <h2>The Page Is Being Designed <br />Thanks For Visiting !!</h2>
    </div>
    </>
  )
}

export default Expertise