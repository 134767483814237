import React from 'react'
import Header from '../components/Header'
import AboutUs from '../components/AboutUs'

const AboutUsPage = () => {
  return (
    <>
    <Header/>
    <AboutUs/>
    </>
  )
}

export default AboutUsPage