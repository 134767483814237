import React from 'react'
import Header from '../components/Header'
import Home from '../components/Home'
import Footer from '../components/Footer'

const HomePage = () => {
  return (
  <>
    <Header/>
   <Home/>
   <Footer/>
   </>
  )
}

export default HomePage