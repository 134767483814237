
import './App.css';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AboutUsPage from './pages/AboutUsPage';
import ContactusPage from './pages/ContactusPage';
import ExpertisePage from './pages/ExpertisePage';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';



function App() {
  return (
   <>
     <Router>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/expertise" element={<ExpertisePage/>} />
          <Route path="/about" element={<AboutUsPage/>} />
          <Route path="/contact" element={<ContactusPage />} />
        </Routes>

    </Router>
   </>
  )
}

export default App;
