import React from 'react'
import Header from '../components/Header'
import Expertise from '../components/Expertise'

const ExpertisePage = () => {
  return (
    <>
    <Header/>
    <Expertise/>
    </>
  )
}

export default ExpertisePage