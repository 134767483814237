import React from 'react';
import { IonIcon } from '@ionic/react'; // Import IonIcon from Ionic framework (assuming it's installed)

const Footer = () => {
    return (
        
       <>
       <h1 className='text-center footerH'>Thanks For Visiting</h1>
       <br /><hr />
       
       <footer className="footer">
            <div className="waves">
                <div className="wave" id="wave1"></div>
                <div className="wave" id="wave2"></div>
                <div className="wave" id="wave3"></div>
                <div className="wave" id="wave4"></div>
            </div>
            <ul className="social-icon">
                <li className="social-icon__item"><a className="social-icon__link" href="#">
                    <IonIcon name="logo-facebook"></IonIcon>
                </a></li>
                <li className="social-icon__item"><a className="social-icon__link" href="#">
                    <IonIcon name="logo-twitter"></IonIcon>
                </a></li>
                <li className="social-icon__item"><a className="social-icon__link" href="#">
                    <IonIcon name="logo-linkedin"></IonIcon>
                </a></li>
                <li className="social-icon__item"><a className="social-icon__link" href="#">
                    <IonIcon name="logo-instagram"></IonIcon>
                </a></li>
            </ul>
            <ul className="menu">
                <li className="menu__item"><a className="menu__link" href="/">Home</a></li>
                <li className="menu__item"><a className="menu__link" href="/about">About</a></li>
                <li className="menu__item"><a className="menu__link" href="/expertise">Services</a></li>
                <li className="menu__item"><a className="menu__link" href="#">Team</a></li>
                <li className="menu__item"><a className="menu__link" href="/contact">Contact</a></li>
            </ul>
            <p>&copy; {new Date().getFullYear()} Harmonic Systems | All Rights Reserved</p>
        </footer>
       </>
    );
};

export default Footer;
