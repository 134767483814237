import React from 'react'

const ContactUs = () => {
  return (
  
    <>
     <div className="contactUs">
      <div className="footer-column contact-form-container">
        <div className="tile-content">
          <form method="POST">
            <h1>Engage with Us</h1>
            <div className="contact-row">
              <input type="text" className="form-field" placeholder="Name" />
            </div>
            <div className="contact-row">
              <input type="text" className="form-field" placeholder="Phone Number" />
            </div>
            <div className="contact-row">
              <textarea rows="5" className="form-field" placeholder="Message"></textarea>
            </div>
            <button className="button">Send</button>
          </form>
        </div>
      </div>
    </div>

    </>
  )
}

export default ContactUs